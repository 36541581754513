// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-approach-js": () => import("./../../../src/pages/approach.js" /* webpackChunkName: "component---src-pages-approach-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-thoughts-js": () => import("./../../../src/pages/thoughts.js" /* webpackChunkName: "component---src-pages-thoughts-js" */),
  "component---src-templates-thought-js": () => import("./../../../src/templates/thought.js" /* webpackChunkName: "component---src-templates-thought-js" */)
}

